<section id="skills">
    <div class="container text-center">
        <h1>Skills &amp; Tools</h1>
        <hr class="separator-double">
        <p>Here are some skills and tools that I use on a regular basis.</p>
        <div class="row">
            <div class="col-lg-12 col-lg-offset-0 col-xs-10 col-xs-offset-1">
                <ul class="skills">
                    <li *ngFor="let skill of primarySkills">
                        <skill-slider>
                            <img skill-image src="{{ skill.image }}" title="{{ skill.title }}" />
                            <div skill-info>{{ skill.title }}<br/><a href="{{ skill.fullUrl }}" target="_blank" title="{{ skill.title }}">{{ skill.friendlyUrl}}</a></div>
                        </skill-slider>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    </section>
<div class="scroll-me">
    <a class="btn btn-scroll-down" pageScroll href="#secondary-skills"></a>
</div>
<aside class="bg-water-fixed">
</aside>
<section id="secondary-skills">
    <div class="container text-center">
        <p>Below are skills and tools that I am familiar with but don't use on a regular basis.</p>
        <div class="row">
            <div class="col-lg-12 col-lg-offset-0 col-xs-10 col-xs-offset-1">
                <ul class="skills">
                    <li *ngFor="let skill of secondarySkills">
                        <skill-slider>
                            <img skill-image src="{{ skill.image }}" title="{{ skill.title }}" />
                            <div skill-info>{{ skill.title }}<br /><a href="{{ skill.fullUrl }}" target="_blank" title="{{ skill.title }}">{{ skill.friendlyUrl}}</a></div>
                        </skill-slider>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
