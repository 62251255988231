<nav class="navbar navbar-inverse navbar-fixed-bottom">
    <div class="navbar-header">
        <a class="navbar-brand" pageScroll href="#page-top">
            <img src="assets/images/logo.png" class="img-responsive img-logo-small">
        </a>
        <button type='button' class='navbar-toggle collapsed' (click)="isCollapsed = !isCollapsed">
            <span class='sr-only'>Toggle navigation</span>
            <span class='icon-bar'></span>
            <span class='icon-bar'></span>
            <span class='icon-bar'></span>
        </button>
    </div>
    <div class='navbar-collapse collapse' [collapse]="isCollapsed">
        <ul class="nav navbar-nav navbar-center">
            <li>
                <a pageScroll href="#page-top">Home</a>
            </li>
            <li>
                <a pageScroll href="#about">About</a>
            </li>
            <li>
                <a pageScroll href="#skills">Skills</a>
            </li>
            <li class="navbar-brand" (mouseenter)="toggle()">
                <a pageScroll href="#page-top">
                    <img [@pulse]="state" src="assets/images/logo.png" class="img-responsive img-logo-small">
                </a>
            </li>
            <li>
                <a href="https://twitter.com/calebwellscodes" target="_blank">Twitter</a>
            </li>
            <li>
                <a href="https://github.com/calebcwells" target="_blank">Github</a>
            </li>
            <li>
                <a href="https://www.linkedin.com/in/calebcwells" target="_blank">LinkedIn</a>
            </li>
        </ul>
    </div>
</nav>