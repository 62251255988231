<section id="about">
    <div class="container">
        <div class="row">
            <div class="col-md-10 col-md-offset-1 text-center">
                <h1 class="text-uppercase text-bold">Caleb Wells</h1>
                <hr class="separator-double">
                <p>
                    I am a web
                    designer and developer in New Orleans, Louisiana. I have a <span title="LinkedIn"
                        class="text-highlight"><a href="https://www.linkedin.com/in/calebcwells"
                            target="_blank">professional background</a></span> in software development and a degree in
                    graphic design from Delta State University.
                </p>
                <p>
                    I'm also passionate about sharing my knowledge.<br />To learn about some of the things I am doing
                    <span title="Explore" class="text-highlight"><a pageScroll href="#explore">explore</a></span>
                    the
                    section below.
                </p>
            </div>
        </div>
    </div>
</section>