<cwcodes-nav></cwcodes-nav>
<header id="page-top" class="bg-water">
    <div class="header-content">
        <img [@pulse]="'active'" src="assets/images/logo.png" class="img-responsive img-center">
    </div>
</header>
<cwcodes-about></cwcodes-about>
<div class="scroll-me">
    <a class="btn btn-scroll-down" pageScroll href="#skills"></a>
</div>
<aside class="bg-water-fixed">
</aside>
<cwcodes-skills></cwcodes-skills>
<div class="scroll-me">
    <a class="btn btn-scroll-down" pageScroll href="#explore"></a>
</div>
<aside class="bg-water-fixed">
</aside>
<cwcodes-explore></cwcodes-explore>
<!-- <cwcodes-podcast></cwcodes-podcast>
<div class="scroll-me">
    <a class="btn btn-scroll-down" pageScroll href="#youtube"></a>
</div>
<aside class="bg-water-fixed">
</aside>
<cwcodes-youtube></cwcodes-youtube> -->
<footer class="bg-water-fixed">
    <div class="container text-center">
        <p>&copy; {{ currentYear }} Caleb Wells</p>
    </div>
</footer>