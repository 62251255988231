<section id="explore">
    <div class="container">
        <div class="row">
            <div class="col-md-8 col-md-offset-2">
                <h1 class="text-center">Explore</h1>
                <hr class="separator-double" />
                <p>
                    If you've gotten this far, you're probably curious about what I'm working on. Dive deeper into these
                    projects and
                    interests: </p>
                <h3>Current Projects</h3>
                <ul>
                    <li>In the process of updating this site to use Blazor.</li>
                    <li>I am currently developing a course for <span title="Newline" class="text-highlight"><a
                                href="https://www.newline.co/" target="_blank">Newline</a></span> that delves into
                        Blazor render modes in .NET 8.</li>
                </ul>
                <h3>Past Work</h3>
                <ul>
                    <li>Head over to my <span title="YouTube" class="text-highlight"><a
                                href="https://www.youtube.com/@calebwellscodes" target="_blank">YouTube</a></span>
                        channel for a few videos on development.</li>
                    <li>For a few years, I co-hosted the <span title="Adventures in .NET" class="text-highlight"><a
                                href="https://topenddevs.com/podcasts/adventures-in-net" target="_blank">Adventures
                                in
                                .NET
                            </a></span> podcast, sharing insights on the .NET ecosystem.</li>
                </ul>
            </div>
        </div>
    </div>
</section>